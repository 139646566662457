@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply overflow-x-hidden font-sans;
    @apply bg-stone-50 text-stone-900 dark:bg-stone-900 dark:text-stone-50;
  }
}
