.indicator {
  @apply absolute w-3 h-3 -ml-1 bg-red-500 rounded-full;
}

.summary {
  @apply absolute -ml-1 w-80 md:w-96;
}

.summary > h1 {
  @apply font-serif text-2xl leading-relaxed;
}

.summary > h5 {
  @apply flex items-center gap-1 text-xs;
}

.summary > h5 .material-icons {
  @apply text-base;
}

.summary > h6 {
  @apply text-xs font-semibold uppercase;
}

.summary > p {
  @apply mt-3 text-sm text-justify;
}

.summary > p > a {
  @apply font-semibold text-red-500 hover:text-red-600;
}

.image {
  @apply absolute h-64 -ml-1 rounded shadow cursor-pointer bg-stone-50 dark:bg-stone-50 dark:shadow-none;
  @apply transition-transform duration-300;
}

.image:hover {
  transform: scale(105%);
}

.image > img {
  @apply object-cover h-64 p-2 rounded aspect-[16/10];
}
